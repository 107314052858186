/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { IikoUnsolvedHeader, IikoUnsolvedTable } from "components";
import { useTranslation } from "localization/translation";

import {
  IIKO_TABLE_TYPES,
  FIRST_PAGE,
  LIMIT,
  MERGE_ERROR_MSG,
  ROUTES,
} from "Constants";
import { useGetData, usePostData } from "hooks";
import * as Api from "service";
import useStore from "context";
import arrowDown from "assets/icons/arrow-down.svg";

export const IIkoResolve = () => {
  const { state } = useLocation();
  let dontRefresh;

  const [clientQuery, setClientQuery] = useState({
    page: FIRST_PAGE,
    limit: LIMIT,
    unit: state?.goods_unit || null,
    status: null,
    search: "",
    ids: state?.goods,
  });
  const [iikoQuery, setIikoQuery] = useState({
    page: FIRST_PAGE,
    limit: LIMIT,
    unit: null,
    status: null,
    search: "",
    ids: state?.iiko,
  });

  const isFromProducts = state?.isFromProducts;

  const [selectedElement, setSelectedElement] = useState({
    iiko: state?.iiko || null,
    good: state?.goods ? `${state?.goods}` : null,
    good_measurement_unit: null,
    iiko_measurement_unit: state?.goods_unit || null,
  });

  const { setErrorMsg } = useStore();

  const translation = useTranslation();
  const history = useHistory();

  const [iikoData, , refreshIiko] = useGetData(
    Api.iikoUnresolvedProducts.getAll,
    iikoQuery
  );
  const [clientData, , refreshGoods] = useGetData(
    Api.clientUnresolvedProducts.getAll,
    clientQuery
  );

  const postCallback = usePostData(
    Api.mergeProducts.create,
    dontRefresh,
    !state?.isEdit
  );

  const handleBack = () => {
    history.goBack();
  };

  const handleMerge = () => {
    if (!selectedElement.iiko || !selectedElement.good) {
      return setErrorMsg(MERGE_ERROR_MSG);
    }

    const data = {
      integratedGoodId: selectedElement.iiko,
      clientGoodId: selectedElement.good,
    };
    postCallback(data).then(() => {
      const updatedGoods = state.goods
        ? state.goods
            .split(",")
            .filter((id) => id !== data.clientGoodId)
            .join(",")
        : "";
      history.replace(ROUTES.iiko_unresolved, {
        iiko: null,
        goods: updatedGoods,
      });
      setSelectedElement(() => ({
        iiko: null,
        good: updatedGoods,
        good_measurement_unit: null,
        iiko_measurement_unit: null,
      }));

      refreshIiko();
      refreshGoods({ ...clientQuery, ids: updatedGoods });
    });
  };

  useEffect(() => {
    setClientQuery((prev) => ({
      ...prev,
      unit: selectedElement?.iiko_measurement_unit,
      page: FIRST_PAGE,
    }));
  }, [selectedElement.iiko]);

  useEffect(() => {
    setIikoQuery((prev) => ({
      ...prev,
      unit: selectedElement?.good_measurement_unit,
      page: FIRST_PAGE,
    }));

    setClientQuery((prev) => ({
      ...prev,
      page: FIRST_PAGE,
      unit: selectedElement?.iiko_measurement_unit,
      ids: selectedElement.good,
    }));
  }, [selectedElement.good]);

  return (
    <div className="iiko_resolve_page">
      <button className="back_button" onClick={handleBack}>
        <img src={arrowDown} alt="arrow" className="arrow_icon" />
        <span>{translation.back}</span>
      </button>
      <IikoUnsolvedHeader
        setIikoQuery={setIikoQuery}
        setClientQuery={setClientQuery}
        handleMerge={handleMerge}
      />
      <div className="iiko_tables">
        <IikoUnsolvedTable
          data={iikoData?.data}
          type={IIKO_TABLE_TYPES.iiko}
          title={translation.iiko_products}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          total={iikoData?.total}
          page={iikoQuery?.page}
          description={translation.select_product}
          setQuery={setIikoQuery}
          limit={iikoData?.limit}
          isEdit={state?.isEdit}
          state={state}
        />
        <IikoUnsolvedTable
          data={clientData?.data}
          type={IIKO_TABLE_TYPES.good}
          title={translation.goods}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          total={clientData?.total}
          page={clientQuery?.page}
          description={translation.select_product}
          setQuery={setClientQuery}
          limit={clientData?.limit}
          state={state}
          isFromProducts={isFromProducts}
        />
      </div>
    </div>
  );
};
