export const localizationEnglish = {
  about_us: "About Us",
  about: "About",
  how_it_work: "How It Work",
  how_it_works: "How It Works",
  contacts_us: "Contacts Us",
  faq: "FAQ",
  dashboard: "Dashboard",
  log_out: "Log out",
  product: "Product",
  terms_of_use: "Terms of Use",
  privacy_policy: "Privacy Policy",
  mobile_app: "Mobile App",
  management_tool: "Management Tool",
  bar_control: "Bar Control",
  owner_operator_company_name: "Company Name",
  e_mail: "E-Mail",
  phone_number: "Phone Number",
  comment: "Comment",
  request_a_callback: "Request a Callback",
  frequently_asked_questions: "Frequently Asked Questions",
  branch_management: "Branch management",
  branches: "Branches",
  request_for_new_branch: "Request for new branch",
  manager_invitations: "Manager invitations",
  managers: "Managers",
  barmen_list: "Barmen list",
  goods_type: "Goods type",
  goods: "Goods",
  inventories: "Inventories",
  iiko: "Iiko",
  integration: "Integration",
  navigation_menu: "Navigation menu",
  items: "Items",
  name: "Name",
  phone: "Phone",
  email: "Email",
  thank_you_email_is_verified: "Thank you. Email is verified.",
  address: "Address",
  latitude: "Latitude",
  longitude: "Longitude",
  actions: "Actions",
  action: "Action",
  active: "Active",
  not_active: "not active",
  branch_requests: "Branch requests",
  create: "Create",
  requested_branches_count: "Requested branches count",
  message: "Message",
  status: "Status",
  barcode: "Barcode",
  full_bottle: "Full Bottle",
  bottle_count: "Bottle count",
  left_over_ml: "Left over",
  created_at: "Created at",
  updated_at: "Updated at",
  deleted_at: "Deleted at",
  count_of_bottles: "Count of bottles",
  left_over: "Leftover",
  save: "Save",
  save_filter: "Save filter",
  sign_in: "Sign In",
  sign_in_subtitle: "If you don’t have an account register You can",
  sign_up_here: "Sign Up here",
  sign_up: "Sign Up",
  sign_up_subtitle: "If you already have an account, please",
  sign_in_here: "Sign In here",
  role: "Role",
  branch_name: "Branch Name",
  created: "Created",
  full_name: "Full Name",
  company_name: "Company Name",
  company_business_address: "Company business Address",
  branch_count: "Branch Count",
  company_email: "Company Email",
  tax_code: "Tax Code",
  registration_number: "Registration Number (optional)",
  goods_types: "Goods Types",
  weight_full: "Weight full",
  weight_full_g: "Weight full (g)",
  weight_empty: "Weight empty",
  weight_empty_g: "Weight empty (g)",
  select_branch: "Select branch",
  type: "Type",
  in_progress: "In progress",
  in_progress_status: "In progress",
  complete: "Complete",
  branch: "Branch",
  order: "Order",
  newest: "Newest",
  oldest: "Oldest",
  email_address: "Email Address",
  fill_the_address: "Fill the address",
  search_terms: "Search terms...",
  search_orders: "Search orders...",
  password: "Password",
  repeat_password: "Repeat password",
  token_is_expired: "Token is expired.",
  goods_name: "Goods name",
  restaurant_name: "Restaurant name",
  scales: "Scales",
  all_statuses: "All statuses",
  pending: "Pending",
  completed: "Completed",
  rejected: "Rejected",
  processing: "Processing",
  cancelled: "Cancelled",
  branches_count: "Branches count",
  date: "Date",
  details: "Details",
  goods_requests: "Goods requests",
  good_detail: "Good detail",
  s_n: "S/N",
  manufacturer: "Manufacturer",
  production_date: "Production date",
  mac: "Mac",
  is_active: "Is active",
  change_user_status: "Are you sure you want to change the user status?",
  reject: "Reject",
  accept: "Accept",
  text: "Text",
  contact: "Contact",
  view: "View",
  image: "Image",
  slides: "Slides",
  close_drawer: "Close drawer",
  bamburgh_pro: "Bamburgh React Admin Dashboard with Material-UI PRO",
  collapse_sidebar: "Collapse sidebar",
  expand_sidebar: "Expand sidebar",
  cancel: "Cancel",
  want_to_reject: "Are you sure you want to reject?",
  want_to_accept: "Are you sure you want to accept?",
  confirmed: "Confirmed",
  analytics: "Analytics",
  statistics: "Statistics",
  overview: "Overview",
  back_to_dashboard: "Back to dashboard",
  chat: "Chat",
  calendar: "Calendar",
  file_manager: "File Manager",
  projects: "Projects",
  profile: "Profile",
  profile_completion: "Profile completion",
  monitoring_dashboard: "Monitoring Dashboard",
  analytics_dashboard: "Analytics Dashboard",
  statistics_dashboard: "Statistics Dashboard",
  view_profile: "View profile",
  web_content: "Web content",
  users: "Users",
  messages: "Messages",
  terms_and_conditions: "Terms and Conditions",
  main_slider: "Main Slider",
  benefit_section: "Benefit section",
  steps_section: "Steps Section",
  image_section: "Image Section",
  items_section: "Items Section",
  clients_section: "Clients Section",
  mobile_section: "Mobile Section",
  management_section: "Management Section",
  contact_section: "Contact Section",
  footer_section: "Footer Section",
  learn_more: "Learn more",
  accountant_profile: "This is an accountant profile",
  logout: "Logout",
  tasks: "Tasks",
  reports: "Reports",
  stats: "Stats",
  server_stats: "Server Stats",
  view_more_items: "View more items",
  sales_target: "Today's sales target",
  daily_statistics: "Daily statistics regarding your sales targets",
  generate_report: "Generate report",
  view_complete_report: "View complete report",
  mistaken_idea: "But I must explain to you how all this mistaken idea.",
  wonderful_serenity: "A wonderful serenity has taken possession.",
  presidential_scandal: `Bill Clinton's presidential scandal makes it
   online.`,
  java_exam_day: "Java exam day",
  business_investor_meeting: "Business investor meeting",
  new: "New",
  graphical_browser: `Mosaic, the first graphical browser, is
                                introduced to the average consumer.`,
  own_image: "Who formed us in his own image, and the breath.",
  dummy_text_acting: "This is a dummy text acting ...",
  files_ready: "Files ready",
  add: "Add",
  decrease: "decrease",
  cards_examples: "Cards examples",
  form_wizards: "Form wizards",
  pricing_tables: "Pricing tables",
  dashboards: "Dashboards",
  there_are: "There are",
  multiple: "multiple",
  dashboard_layouts_available_en: "dashboard layouts available!",
  helpdesk: "Helpdesk",
  crm_ui: "CRM UI",
  customers: "Customers",
  delete: "Delete",
  menu_box: "This menu box is scrollable (sm)",
  services: "Services",
  others: "Others",
  components: "Components",
  view_details: "View details",
  web_developer: "Web developer",
  javascript: "Javascript",
  angular: "Angular",
  incapable_of_drawing: `I should be incapable of drawing a single stroke at the
                  present moment; and yet I feel that I never was a greater
                  artist than now.`,
  team_members: "Team members",
  view_complete_profile: "View complete profile",
  wonderful_serenity_has_possession: "Wonderful serenity has possession",
  please_wait_while_load: "Please wait while we load the live preview examples",
  preview_instance: `This live preview instance can be slower than a real
                                production build!`,
  add_new_entry: "Add new entry",
  stay_up_to_date: "Stay up to date",
  subscribe_to_stay_up_to_date:
    "Subscribe to stay up to date with our latest releases and updates!",
  results_per_page: "results per page",
  ascending: "Ascending",
  descending: "Descending",
  order_details: "Order details",
  source: "Source",
  order_id: "Order ID",
  balance: "Balance",
  amount: "Amount",
  fiat_deposit: "Fiat deposit",
  bank_account: "Bank Account",
  deposit: "Deposit",
  paypal_withdraw: "Paypal withdraw",
  crypto_wallet: "Crypto Wallet",
  my_bitcoin_wallet: "My Bitcoin Wallet",
  withdraw: "Withdraw",
  current_month: "Current month",
  your_daily_tasks_list: "Your daily tasks list",
  finish_tasks_for_today: "Finish tasks for today",
  submit_report: "Submit Report",
  get_in_touch: "Get in touch",
  MySpace_network: "MySpace becomes the most popular social network.",
  pending_actions: "You have pending actions to take care of.",
  total_sales: "Total Sales",
  total_performance: "Total performance for selected period",
  world_among_the_stalks: "World among the stalks",
  deploy_new_website: "Deploy new website",
  comments_available: "There are 653 new comments available!",
  latest_sales: "Latest sales",
  increase: "Increase",
  last_year: "Last year",
  new_messages: "new messages",
  world_wide_web: `The World Wide Web goes live with its first web
                            page.`,
  clinton_presidential: "Bill Clinton's presidential scandal makes it online.",
  first_graphical_browser: `Mosaic, the first graphical browser, is introduced
                            to the average consumer.`,
  learning_round_table_gathering: "Learning round table gathering",
  incoming_messages: "Incoming messages",
  results: "Results",
  all_orders: "All Orders",
  all_types: "All types",
  buy_crypto: "Buy Crypto",
  sell_crypto: "Sell Crypto",
  transfer_funds: "Transfer Funds",
  filter_results: "Filter results",
  delete_entry: "Are you sure you want to delete this entry?",
  cannot_undo_operation: "You cannot undo this operation.",
  enable_disable: "Enable/Disable",
  login: "Login",
  fill_fields: "Fill in the fields below to login to your account",
  or_sign_in_with_credentials: "or sign in with credentials",
  serial_number: "Serial number",
  mac_address: "Mac address",
  weight_empty_bigger_full:
    "Weight empty can't be bigger or equal than weight full",
  full_bottle_weight_gram: "Full Bottle weight (gram)",
  full_bottle_weight_kilogram: "Full Bottle weight (kilogram)",
  empty_bottle_weight_gram: "Empty Bottle weight (gram)",
  empty_bottle_weight_kilogram: "Empty Bottle weight (kilogram)",
  volume_milliliter: "Volume (milliliter)",
  volume_liter: "Volume (liter)",
  crafted_with: "crafted with",
  by: "by",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  last_week: "Last week",
  last_month: "Last month",
  last_quarter: "Last quarter",
  notifications: "Notifications",
  you_have: "You have",
  timeline: "Timeline",
  blinded_by_desire: "Blinded by desire",
  mega_menu: "Mega menu",
  real_estate: "Real Estate",
  applications: "Applications",
  contacts: "Contacts",
  layouts: "Layouts",
  view_documentation: "View documentation",
  view_product_details: "View product details",
  Powerful_admin_dashboard:
    "Powerful admin dashboard templates & ui kits that are easy to use and customize.",
  user: "User",
  city: "City",
  home: "Home",
  good_types: "Good types",
  good_type: "Good type",
  remember_me: "Remember me",
  crypto_components: "Crypto Components",
  buttons: "Buttons",
  first_ever_iPod: "First ever iPod launches.",
  latest_reports_available: "Latest reports available",
  browse: "Browse file",
  file_for_upload: "File for upload",
  file_will_be_uploaded: "file will be uploaded",
  chosen_file_appear: "Chosen file will appear here!",
  all_files_will_be_uploaded: "All files will be uploaded!",
  some_files_will_be_rejected: "Some files will be rejected!",
  drag_and_drop: "Drag and drop",
  file: "file ",
  files: "files ",
  here: "here",
  or: "or",
  jpg_png_images: "(jpg/png images)",
  for_upload: "for upload",
  chosen: "Chosen",
  will_appear_here: "will appear here!",
  success: "success",
  files_click: "files! click",
  tables: "Tables",
  change_password: "Change password",
  title: "Title",
  inputs: "Inputs",
  question: "Question",
  answer: "Answer",
  secondary: "Secondary",
  play_market: "Play market",
  app_store: "App store",
  description: "Description",
  average_density: "Average density",
  density: "Density",
  volume: "Volume",
  litre: "Litre",
  all_fields_are_required: "All fields are required",
  forgot_password: "Forgot your password?",
  email_is_not_valid: "Email is not valid",
  password_characters: "Password should contain 6 or more characters.",
  check_your_email: "Check your email.",
  reset: "Reset",
  settings: "Settings",
  inventory: "Inventory",
  empty_branches: "Empty branches, please try again.",
  in_process: "In process",
  bar_title: "Bar title",
  no_inventories: "No Inventories yet. Want to create an inventory?",
  complete_inventory: "Complete Inventory",
  export_inventory: "Export Inventory",
  export_as: "Export as",
  edit: "Edit",
  edit_profile: "Edit Profile",
  choose_from_photo_library: "Choose from photo library",
  take_from_camera: "Take from camera",
  user_info_successfully_updated: "User info successfully updated",
  first_name_empty: "First name can't be empty",
  last_name_empty: "Last name can't be empty",
  title_empty: "Title can't be empty",
  edit_profile_photo: "Edit Profile Photo",
  first_name: "First Name",
  last_name: "Last Name",
  change: "Change",
  current_password: "Current password",
  new_password: "New password",
  confirm_password: "Confirm password",
  something_went_wrong: "Something went wrong",
  litrage: "Litrage",
  measuring_time: "Measuring Time",
  time: "Time",
  bottles_qty: "Bottles Qty",
  delete_inventory: "Delete inventory",
  edit_inventory: "Edit inventory",
  leftover: "Leftover",
  delete_the_inventory: "Are you sure you want to delete the inventory?",
  bottles: "Bottles",
  bottles_count: "Bottles count",
  bottle_weight: "Bottle weight (gram)",
  consider_bottle_barcode: "Please consider to scan the bottle barcode.",
  valid_bottles_count: "Please input valid bottles count.",
  valid_bottle_weight: "Please input valid bottle weight.",
  weight_empty_weight: "Bottle weight can't be less than empty bottle weight.",
  weight_full_weight: "Bottle weight can't be more than full bottle weight.",
  good_not_exists: "good not exists",
  barcode_doesnt_exist:
    "good with the barcode doesn't exist. Would you like to create it?",
  inventory_result_successfully: "The inventory result was successfully added.",
  open_bottle_weight: "Open bottle weight (gram)",
  open_bottle_weight_kg: "Open bottle weight(kilogram)",
  quantity: "Quantity",
  pairing_status: "Pairing status",
  connected: "Connected",
  not_connected: "Not Connected",
  bluetooth_is_off: "Bluetooth is off",
  please_turn_on_bluetooth: "Please turn on your Bluetooth",
  scan_barcode: "Scan the barcode",
  turn_on_bluetooth: "Turn on Bluetooth",
  connect_to_scale: "Connect to scale",
  start_scaling: "Start Scaling",
  check_the_scale: "Check the scale",
  permission_not_allowed: "Permission not allowed.",
  allow_now: "Would you like to allow it now?",
  yes: "Yes",
  no: "No",
  sure_complete_inventory: "Are you sure you want to complete the inventory?",
  litres: "L",
  milliliters: "ml",
  no_scaling_yet: "No scaling yet for the particular inventory",
  search: "Search",
  search_goods_name: "Search by goods Name",
  bottle_volume: "Bottle Volume",
  full_bottle_weight_kg: "Full Bottle weight (kilogram)",
  empty_bottle_weight_kg: "Empty Bottle weight (kilogram)",
  select_type: "Select type",
  density_g_cm: "Density(g/cm³)",
  density_kg_m: "Density(kg/m³)",
  create_good: "Create Good",
  create_good_success: "The good was successfully created.",
  update_good_success: "The good was successfully updated.",
  good_already_exist: "The good already exist",
  good: "Good",
  empty_current_password: "Current password can't be empty",
  empty_new_password: "New password can't be empty",
  empty_confirm_password: "Confirm password can't be empty",
  passwords_doesnt_match: "Passwords doesn't match",
  password_updated: "Password successfully changed",
  on: "On",
  off: "Off",
  autosave: "Autosave",
  weight: "Weight",
  language: "Language",
  milliliter: "milliliter",
  connect_scale_bluetooth: "Connect to scale via Bluetooth",
  Place_bottle_scale: "Place the bottle on a scale",
  scan_bottle_barcode: "Scan bottle's barcode",
  report_scaling_result: "Report scaling result",
  ok: "Ok",
  edited: "Edited",
  deleted: "Deleted",
  unmodified: "Unmodified",
  add_full_bottles: "Add full bottles?",
  disconnected: "Disconnected",
  g: "g",
  kg: "kg",
  g_cm: "g/cm",
  kg_m: "kg/m",
  are_you_sure: "Are you sure?",
  account_activation: "Account activation",
  enter_password_account: "Enter password to activate your account",
  email_isnt_verified: "Email isn't verified, something went wrong!",
  can: "You can",
  agree: "I agree to the website",
  terms: "terms & conditions",
  and: "and",
  thank_you_register: "Thank you for registration!",
  successfully_sign_up:
    "Thank you for registering with us! We have received your application and we appreciate your interest in our services. Our team will review your application as soon as possible, and we will contact you to provide further information on the status of your registration.",
  help_reset_password: "We’ll help you reset it and get back on track.",
  reset_password: "Reset password",
  have_account: "If you have an account, we’ll email you a reset link.",
  download: "Download",
  for_trackers: "For truckers",
  faq_title: "Frequently asked questions",
  deactivated: "Deactivated",
  are_you_sure_you_want_to: "Are you sure you want to ",
  requested: "Requested",
  canceled: "Canceled",
  accepted: "Accepted",
  expired: "Expired",
  action_buttons: "Actions",
  deactivate: "Deactivate",
  activate: "Activate",
  export_pdf: "Export as PDF",
  export_excel: "Export as Excel",
  measurement: "Measurement unit",
  export_extended_pdf: "Export as extended PDF",
  export_extended_excel: "Export as extended Excel",
  settings_title: "Change Measurement Settings",
  gram: "gram",
  kilogram: "kilogram",
  liter: "liter",
  adjust_volume: "Adjust volume settings",
  adjust_weight: "Adjust weight settings",
  measurement_unit: "Measurement Unit",
  sync_message: "To view your products, you must sync your product data.",
  unresolved: "Mismatched",
  solved: "Matched",
  sync: "Sync",
  all: "All",
  iiko_products: "Iiko Products",
  semi_manufactures: "Semi-finished products",
  search_iiko: "Search Iiko Product",
  search_good: "Search BarControl Product",
  merge: "Merge",
  select_product: "Please select product for merging",
  unit: "Measurement unit",
  send: "Send",
  confirm: "Confirm",
  choose_store: "Please select the store",
  prepare: "Prepare",
  inventory_prepare: "Prepare Inventory",
  store: "Stores",
  send_warning_msg: "Please match products manually in integration page",
  resolve: "Match",
  unresolved_products: "mismatched BC goods.",
  unresolved_by_unit: "Mismatched by measurement unit",
  manually_resolved: "Manually matched",
  resolve_goods: "Match goods",
  unresolved_goods: "mismatched Iiko products.",
  unresolved_unit_goods: "mismatched by unit goods.",
  successfully_sync: "You have successfully synced to iiko.",
  credential_warning: "Please fill credentials information from ",
  credentials_settings: "Settings",
  continue_sync: " to continue iiko sync process.",
  you_have_unresolved: "You have",
  back: "Back",
  history_of_scales: "History of scaling",
  full: "Full",
  open: "Open",
  after: "After",
  before: "Before",
  iiko_settings_success_msg: "Successfully synchronized",
  in_package: "Qty in package",
  confirm_action: "Confirm Action",
  iiko_deletion_msg:
    "Attention: Making changes to the settings will result in the deletion of all iiko products. Are you sure you want to proceed?",
  next: "Next",
  export_as: "Export as",
  total_left_over: "Total left over",
  remaining_branches: "Remaining branches",
  updated: "Updated",
  order_changed: "Order changed",
  added: "Added",
  are_you_sure_you_want_to_delete:
    "Are you sure you want to delete this entry?",
  you_cannot_undo: "You cannot undo this operation.",
  support: "Support",
  are_you_sure_you_want_to_delete_good:
    "Are you sure you want to delete this entry? It has been used in inventory !",
  inventory_send_message: "Successfully sent",
  invalid_password_message:
    "Your password must contain at least 8 characters without spaces",
  passwords_must_match: "Passwords do not match.",
  account_activated: "Account activated",
  should_be_positive_number: "Value should be a positive number",
  please_enter_valid_phone: "Please enter valid phone number.",
  but: "However,",
  have_been_deleted: " of them have been deleted.",
};
