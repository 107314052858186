import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Fade,
  Typography,
  Tooltip,
} from "@material-ui/core";

export function PdfExcelDropdown({
  name,
  extendedName,
  exportFile,
  exportExtendedFile,
  children,
  title,
  customClass = "",
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportFile = () => {
    exportFile();
    handleClose();
  };

  const handleExportExtendedFile = () => {
    exportExtendedFile();
    handleClose();
  };

  return (
    <div className="d-inline">
      <Tooltip placement={`top`} title={title}>
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="contained"
          color="secondary"
          className={`btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center ${customClass}`}
        >
          {children}
        </Button>
      </Tooltip>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem>
          <Typography onClick={handleExportFile}>{name}</Typography>
        </MenuItem>
        <MenuItem>
          <Typography onClick={handleExportExtendedFile}>
            {extendedName}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
