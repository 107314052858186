/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  Container,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import PersonIcon from "@material-ui/icons/Person";

import Save from "components/save";
import {
  DISABLE_AUTOCOMPLETE,
  IIKO_HOST,
  IIKO_HOST_KEY,
  IIKO_LOGIN,
  IIKO_PASSWORD,
  LOGIN_INPUT,
  PASSWORD,
  PASSWORD_INPUT,
  TEXT,
} from "Constants";
import { usePutData, useText } from "hooks";
import * as Api from "service";
import useStore from "context";
import { getSettingValue } from "helpers/getSettingValue";
import { useTranslation } from "localization/translation";
import { SimplePopup } from "components";

const updateRefresh = false;
const updateRoute = false;
const dontShowMsg = true;

const IikoLogin = () => {
  const [initForm, setInitForm] = useState({
    iiko_host: "",
    iiko_login: "",
    iiko_password: "",
  });
  const [openPopup, setOpenPopup] = useState(false);

  const [isDisableSaveBtn, setIsDisableSaveBtn] = useState(false);
  const { settings, setErrorMsg, setSuccessMsg, refresh } = useStore();

  const translation = useTranslation();

  const [text, , , input] = useText(initForm);

  const updateCallback = usePutData(
    Api.settings.update,
    updateRefresh,
    updateRoute,
    dontShowMsg
  );

  const submitData = async () => {
    let hasChange = false;

    for (let key in text) {
      if (text[key] !== getSettingValue(key, settings)) {
        hasChange = true;
        const data = {
          key,
          value: text[key],
        };
        await updateCallback(data);
      }
    }
    if (hasChange) {
      setSuccessMsg(translation.iiko_settings_success_msg);
      refresh();
      Api.main.deleteIikoProducts();
    }
    setOpenPopup(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    Api.main
      .checkIikoConnection(text)
      .then(async (res) => {
        if (res.success) {
          setOpenPopup(true);
        }
      })
      .catch((err) => setErrorMsg(err.message));
  };

  useEffect(() => {
    let isDisable = true;
    for (let key in initForm) {
      if (initForm[key] !== text[key]) {
        isDisable = false;
      }
    }
    setIsDisableSaveBtn(isDisable);
  }, [text]);

  useEffect(() => {
    for (let key in initForm) {
      const element = settings.find((elem) => elem.key === key)?.value;
      setInitForm((prev) => ({
        ...prev,
        [key]: element,
      }));
      if (!!element) {
        setIsDisableSaveBtn(true);
      }
    }
  }, []);

  return (
    <Container maxWidth="md">
      <SimplePopup
        open={openPopup}
        setOpen={setOpenPopup}
        name={translation.confirm_action}
        onClickHandler={submitData}
        text={translation.iiko_deletion_msg}
      />
      <Card className="shadow-xxl px-4 py-4">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            type={TEXT}
            label={IIKO_HOST}
            {...input(IIKO_HOST_KEY)}
          />
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            type={TEXT}
            label={LOGIN_INPUT}
            {...input(IIKO_LOGIN)}
          />
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            autoComplete={DISABLE_AUTOCOMPLETE}
            type={PASSWORD}
            label={PASSWORD_INPUT}
            {...input(IIKO_PASSWORD)}
          />
          <Save isDisable={isDisableSaveBtn} />
        </Box>
      </Card>
    </Container>
  );
};

export default IikoLogin;
