import React, { useCallback, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import styles from "./styles.module.scss";
import Input from "components/login-input";
import { useTextFront } from "hooks";
import * as Api from "service";
import useStore from "context";
import { Checkbox, Header } from "components";
import { isValidForm, isValidField } from "helpers/formValidation";
import { useTranslation } from "localization/translation";
import {
  EMAIL,
  PASSWORD,
  NEW_PASSWORD,
  TOKEN,
  ROUTES,
  HEADER_URLS,
} from "Constants";
import PasswordInput from "shared/components/PasswordInput";

const initForm = {
  email: "",
  password: "",
};

const validation = {
  email: "email:required",
  password: "string:required",
};

const SignIn = () => {
  const translation = useTranslation();
  const [text, , , input] = useTextFront(initForm);
  const [error, setError] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fieldError, setFieldError] = useState({});
  const history = useHistory();
  const { setUser } = useStore();

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitted(true);
      e.preventDefault();
      if (isValidForm({ text, validation, setFieldError })) {
        try {
          setError("");

          const data = {
            ...text,
            rememberMe: isCheck,
          };

          const res = await Api.sign.logIn(data);

          localStorage.setItem(TOKEN, res.token);
          const user = await Api.sign.getUser();
          setUser(user);
          history.push(ROUTES.dashboard);
        } catch (err) {
          setError(err.message);
        }
      }
    },
    [text, isCheck]
  );

  return (
    <section className={styles.signSection}>
      <Header url={HEADER_URLS.sign_in} />
      <div className={styles.mainContainer}>
        <h2>{translation.sign_in}</h2>
        <p>
          {translation.sign_in_subtitle}{" "}
          <Link to={ROUTES.sign_up}>{translation.sign_up_here}</Link>
        </p>

        <form onSubmit={submit}>
          <div className={styles.singByMail}>
            <Input
              {...input.email}
              error={
                isSubmitted &&
                isValidField({ value: text.email, type: EMAIL, required: true })
              }
              helpText={
                isValidField({
                  value: text.email,
                  type: EMAIL,
                  required: true,
                }) && fieldError?.email
              }
              type={EMAIL}
              placeholder={translation.email}
            />
            <PasswordInput
              {...input.password}
              error={
                isSubmitted &&
                isValidField({
                  value: text.password,
                  required: true,
                })
              }
              helpText={
                isValidField({
                  value: text.password,
                  required: true,
                }) && fieldError?.password
              }
              type={PASSWORD}
              autoComplete={NEW_PASSWORD}
              placeholder={translation.password}
            />
            <Checkbox
              label={translation.remember_me}
              checked={isCheck}
              onChange={() => setIsCheck((prev) => !prev)}
            />
            <span className={styles.error}>{error}</span>
            <button type="submit" className="submit-btn">
              {translation.sign_in}
            </button>
          </div>
        </form>
        <Link to={ROUTES.forget_password}>{translation.forgot_password}</Link>
      </div>
    </section>
  );
};

export default SignIn;
