import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'unset !important',
    width: '100%',
    marginBottom: '24px',
    position: 'relative',
    '& legend': {
      fontSize: '10.5px'
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '100%',
      color: 'red',
      fontSize: '11px',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid transparent',
        borderRadius: '12px',
        borderColor: '#05796B !important',
      },
      '& .Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid red !important',
          borderRadius: '12px',
          borderColor: 'red !important',
        }
      },
    },
    '& .Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid red',
        borderRadius: '12px',
        borderColor: 'red !important',
      },
      '& legend': {
        fontSize: '10.5px',
        color: 'red !important',
      },
    },
    '& .MuiInputBase-input': {
      width: '-webkit-fill-available',
      fontFamily: 'Proxima Nova Regular',
      color: '#48484A',
      display: 'flex',
      alignItems: 'center',
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing:' -0.24px',
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: 'red !important',
    },
    '& .Mui-focused': {
      boxShadow: 'unset !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #05796B',
        borderRadius: '12px',
        borderColor: '#05796B',
      },
      '& .Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid red !important',
          borderRadius: '12px',
          borderColor: 'red !important',
        }
      },
    },
  },
  cssOutlinedInput: {
    fontFamily: 'Proxima Nova Regular',
    color: '#48484A',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px',
    letterSpacing:' -0.24px',
  },
  fieldInput: {
    border: '1px solid #C7C7CC',
    borderRadius: '12px',


  },
  cssLabel: {
    fontFamily: 'Proxima Nova Regular',
    color: '#8E8E93',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing:' -0.24px',
    '&.Mui-focused' : {
      color: '#05796B',
      fontSize: '14px',

      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #05796B',
        borderRadius: '12px',
        borderColor: '#05796B !important',
      },
    },
    '& .Mui-error': {
      color: 'red !important',
    }
  },
}));

const Input = ({
  type = "text",
  placeholder,
  autoComplete,
  name,
  value,
  onChange,
  required = false,
  pattern,
  title,
  accept,
  error,
  helpText,
  InputProps: externalInputProps = {},
}) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      className={classes.root}
      required={required}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      label={placeholder}
      variant="outlined"
      autoComplete={autoComplete}
      shrink={true}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
        },
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssInputFocused,
          notchedOutline: classes.fieldInput,
        },
        ...externalInputProps,
      }}
      pattern={pattern}
      title={title}
      accept={accept}
      error={error}
      helperText={helpText}
    />
  );
};
export default Input;
